import swissTheme from '@theme-ui/preset-swiss';

const theme = {
  ...swissTheme,
  initialColorModeName: 'light',
  useColorSchemeMediaQuery: false,
  breakpoints: ['480px', '768px', '1024px', '1200px'],
  colors: {
    ...swissTheme.colors,
  },
  // 他のスタイル設定もここに追加できます
};

export default theme;
